import React, { useEffect, useState } from "react";
import fetchAdminReview, { getReviewer } from "../fetch/providerReviewFetcher";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../redux_slices/userActions";
import { setUser } from "../redux_slices/userSlice";


function ProviderReviewForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const[submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const user = useSelector(state => state.user); // Assuming you have a user in your Redux state
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');
  const providerId = queryParams.get('providerId');
  const [reviewer, setReviewer] = useState(null);

  const [form, setForm] = useState({
    service: "",
    userText: "",
    pricePaid: "",
    providerRating: null,
    priceFairness: null,
    publishName: "",
    reviewerName: reviewer ? reviewer.firstName : null,
    userId, 
    providerId, 
    adminUser: user ? user.userId : null
  });

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if(user === null && authToken){
      const fetchUserData = async () => {
        const data =  await fetchUser(authToken);     
        if (data && data.data && data.data.user) {
          dispatch(setUser(data.data.user));
          setForm(prevForm => ({
            ...prevForm,
            adminUser: data.data.user.userId
          })); 
          if(data.data.user  && userId !== undefined){
            const fetchReviewerData = async () => {
              const data =  await getReviewer(userId);     
              if (data && data.data) {
                  setReviewer(data.data);    
                  setForm(prevForm => ({
                    ...prevForm,
                    reviewerName: data.data.firstName
                  }));     
              }
            }
      
            fetchReviewerData();        
          }
      }
    }
    fetchUserData();

    //fetch the reviewer data
    
    }
  }, [user, userId, dispatch]);

  



  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeProviderRating = (e) => {
    setForm({
      ...form,
      providerRating: parseInt(e.target.value, 10),
    });
  }

  const handleChangePriceFairness = (e) => {
    setForm({
      ...form,
      priceFairness: parseInt(e.target.value, 10),
    });
  }

  const handleSubmit = async(e) => {
    e.preventDefault();

    if(userId === undefined || providerId === undefined){
        console.error('User ID or Provider ID is missing');
        alert('User ID or Provider ID is missing');
        return;
    }
    console.log(form);
    setIsSubmitting(true);

    try {
        const response = await fetchAdminReview(form);
        console.log(response);
        setSubmittedSuccessfully(true)
    } catch (error) {
        console.error(error);
        setIsSubmitting(false);

    }

    // setIsSubmitting(false);
  };

  if(submittedSuccessfully){
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CheckCircleIcon style={{ color: 'green', fontSize: 50, marginBottom: '10px' }} />
        <h2 style={{ color: 'green', marginBottom: '30px' }}>ההודעה נשלחה בהצלחה</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '300px' }}>
          {/* <button style={{ backgroundColor: 'blue', color: 'white', border: 'none', borderRadius: '5px', padding: '10px', marginBottom: '10px', cursor: 'pointer', transition: 'background-color 0.3s', height: '40px', marginRight: '10px', width: '140px' }} onClick={handleClose} onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkblue'} onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'blue'}>סגור</button>
          <button style={{ backgroundColor: 'blue', color: 'white', border: 'none', borderRadius: '5px', padding: '10px', cursor: 'pointer', transition: 'background-color 0.3s', height: '40px', width: '140px' }} onClick={() => navigate('/messages')} onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkblue'} onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'blue'}>צפה בהודעות</button>  */}
        </div>
      </div>
    )
}

  return (
    <div
      dir="rtl"
      className="flex justify-center items-center min-h-screen bg-gray-100"
    >
      <form
        onSubmit={handleSubmit}
        className="flex flex-col space-y-4 bg-white p-8 rounded shadow-lg w-full max-w-screen-xl mx-auto"
      >
        <label className="flex flex-col">
          השירות:
          <input
            type="text"
            name="service"
            value={form.service}
            onChange={handleChange}
            className="border p-2 rounded"
            required
          />
        </label>
        <label className="flex flex-col">
          טקסט חופשי מהמשתמש:
          <textarea
            name="userText"
            value={form.userText}
            onChange={handleChange}
            className="border p-2 rounded"
            required
          />
        </label>
        <label className="flex flex-col">
          המחיר ששולם:
          <input
            type="number"
            name="pricePaid"
            value={form.pricePaid}
            onChange={handleChange}
            className="border p-2 rounded"
            required
          />
        </label>
        <div className="flex flex-col">
          דירוג הספק:
          <div className="flex space-x-2">
            {[1, 2, 3, 4, 5].map((rating, index) => (
              <label key={rating} className="flex items-center">
                <input
                  type="radio"
                  name="providerRating"
                  value={rating}
                  checked={form.providerRating ? form.providerRating.toString() === rating.toString() : false}
                  onChange={handleChangeProviderRating  }
                  className="mr-1"
                  required={index === 0}
                />
                {rating}
              </label>
            ))}
          </div>
        </div>
        <div className="flex flex-col">
          הוגנות המחיר:
          <div className="flex space-x-2">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((fairness, index) => (
              <label key={fairness} className="flex items-center">
                <input
                  type="radio"
                  name="priceFairness"
                  value={fairness}
                  checked={form.priceFairness ? form.priceFairness.toString() === fairness.toString() : false}
                  onChange={handleChangePriceFairness}
                  className="mr-1"
                  required={index === 0}
                />
                {fairness}
              </label>
            ))}
          </div>
        </div>
        <div className="flex flex-col">
          האם אתה מוכן לפרסם את שמך עם הסקירה?
          <div className="flex space-x-2">
            {["true", "false"].map((option, index) => (
              <label key={option} className="flex items-center">
                <input
                  type="radio"
                  name="publishName"
                  value={option}
                  checked={form.publishName === option}
                  onChange={handleChange}
                  className="mr-1"
                  required={index === 0}
                />
                {option === "true" ? "כן" : "לא"}
              </label>
            ))}
          </div>
        </div>
        <button type="submit" disabled={isSubmitting} className={`${isSubmitting ? 'bg-gray-500' : 'bg-blue-500'} text-white p-2 rounded`}>
          שלח
        </button>
      </form>
    </div>
  );
}

export default ProviderReviewForm;

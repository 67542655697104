import { React, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "./redux_slices/userActions";
import { setUser } from "./redux_slices/userSlice";
import ProfileSelection from "./profiles/ProfileSelection";
import ProviderReviewForm from "./form/ProviderReviewForm";
import PersistentDrawerRight from "./drawer/PersistentDrawerRight";
import Users from "./users_drawer/Users";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const user = useSelector((state) => state.user); // Retrieve the user from the Redux store
  const dispatch = useDispatch();

  useEffect(() => {
    const handleLogout = () => {
      setIsLoggedIn(false);
      setUserName("");
    };

    const handleLogin = () => {
      if (user) {
        setIsLoggedIn(true);
        setUserName(user.firstName);
      } else {
        setIsLoggedIn(false);
        setUserName("");
      }
    };

    if (user && !isLoggedIn) {
      handleLogin(user);
      return;
    }
    const authToken = localStorage.getItem("authToken");
    if (!user && authToken) {
      // Check for an existing auth token
      const fetchUserData = async () => {
        //
        const data = await fetchUser(authToken);
        if (data && data.data && data.data.user) {
          dispatch(setUser(data.data.user));
        }
      };
      fetchUserData();
    } else if (!user) {
      handleLogout();
    }
  }, [dispatch, user, isLoggedIn]);

  return (
    <Router>
      <Header isLoggedIn={isLoggedIn} userName={userName} />
      <Routes>
        <Route path="/" element={<ProfileSelection />} />
        <Route path="/tmp" element={<Users />} />
      
        {isLoggedIn ? (
          <Route path="/users" element={<PersistentDrawerRight />} />
        ) : null}
        <Route path="/tmp2" element={<Users />} />
        {isLoggedIn ? (
          <Route path="/review" element={<ProviderReviewForm />} />
        ) : null}
        <Route path="*" element={<div>404 Not Found bla bla </div>} />

      </Routes>
    </Router>
  );
}

export default App;

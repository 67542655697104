import React, { useState } from "react";
import StatusCode from "../utility/StatusCode.js";
import {   setUser } from "../redux_slices/userSlice.js";
import { useDispatch } from "react-redux";
import {  loginUser } from "../redux_slices/userActions.js";

export default function LoginForm(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!email || !password) {
      setError("Please fill in all fields.");
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      const { result, statusCode }  = await loginUser(email, password);

      if (statusCode === StatusCode.OK) {
        // Login successful, handle the User object
        console.log("User:", result);
        props.handleClose();
        dispatch(setUser(result.user));
      
        
      } else {
        if (statusCode === StatusCode.UNAUTHORIZED) {
          setError("Email or Password incorrect!");
        } else if (result.hasOwnProperty("error")) {
          // The 'error' attribute exists in the 'data' object
          setError(result.error);
        } else {
          // The 'error' attribute does not exist in the 'data' object
          setError("Login failed. Please check your credentials.");
        }
      }
    } catch (error) {
      // Handle network or other errors
      setError("An error occurred. Please try again.");
    }
  };

 

  return (
    <div className="mx-auto" style={{ maxWidth: '300px' }}> {/* Adjust width here */}
    <h2 className="text-lg font-medium mb-6">Login</h2>
    {error && <p className="text-red-500 mb-4">{error}</p>}
    <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <input
            type="email"
            className="w-full border p-2 text-black"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            className="w-full border p-2 text-black"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="flex justify-between items-center mb-4">
          <button className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white">
            Log In
          </button>
          <a href="#" className="text-blue-500 text-sm">
            Forgot Password?
          </a>
        </div>
        <div className="text-center text-sm text-gray-500">
          Don't have an account yet?{" "}
          <a className="cursor-pointer text-blue-500" onClick={props.onToggle}>
            Sign Up
          </a>
        </div>
      </form>
    </div>
  );
}

// ProviderReviewFetcher.js

import { API_HOST } from "../config/config";
import UnauthorizedError from "../errors/UnauthorizedError";
import { signout } from "./signoutAction";

const fetchAdminUsers = async (adminUserId) => {
    const authToken = localStorage.getItem('authToken');
    if(authToken === null) throw new Error('No auth token found');

    const response = await fetch(`${API_HOST}/fetchAdminUsers`, {  // Replace '/api/reviews' with your API endpoint
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({ userId: adminUserId }),
    });

    if (!response.ok) {
        if(response.status === 401){
            throw new UnauthorizedError('Unauthorized access');       
        }
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
};


export const fetchUserDetails = async (userId) => {
    const authToken = localStorage.getItem('authToken');
    if(authToken === null) throw new UnauthorizedError('No auth token found');

    const response = await fetch(`${API_HOST}/fetchUserDetails`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({ userId }),
    });

    if (!response.ok) {
        if(response.status === 401){
            throw new UnauthorizedError('Unauthorized access');       
        }
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
};

export default fetchAdminUsers;
// ProviderReviewFetcher.js

import { API_HOST } from "../config/config";

const fetchAdminReview = async (reviewData) => {
    const authToken = localStorage.getItem('authToken');
    if(authToken === null) throw new Error('No auth token found');

    const response = await fetch(`${API_HOST}/providerReview`, {  // Replace '/api/reviews' with your API endpoint
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify(reviewData),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
};

export default fetchAdminReview;

export const getReviewer = async (userId) => {
    const authToken = localStorage.getItem('authToken');
    if(authToken === null) throw new Error('No auth token found');

    const response = await fetch(`${API_HOST}/users/${userId}`, {  // Replace '/api/reviews' with your API endpoint
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },  
    });
    return response.json();
}



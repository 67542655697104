import { setUser } from "../redux_slices/userSlice";



// Async Action Creator
export const signout = () => {
    return (dispatch) => {
        localStorage.removeItem('authToken');
        dispatch(setUser(null));
        window.location.href = '/login';
    };
};
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useDispatch } from "react-redux";
import ProviderReviewForm from "../form/ProviderReviewForm";
import { setUser } from "../redux_slices/userSlice";
import Users from "../users_drawer/Users";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: theme.palette.primary.main, // Custom color change
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function PersistentDrawerRight() {
    const MENU_TITLES = {
      USERS_TABLE: "יוזרים",
      CLOSED_CONTACTS: "לידים סגורים",
    };
    const theme = useTheme();
    const isMobile = window.innerWidth <= 768; // You can adjust the width threshold as needed
    const [open, setOpen] = React.useState(!isMobile);
    
    const [item, setItem] = React.useState( { text: MENU_TITLES.USERS_TABLE, icon: <InboxIcon />, component: <Users/> });
    const dispatch = useDispatch();
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

  const menuItems = [
    { text: MENU_TITLES.USERS_TABLE, icon: <InboxIcon />, component: <Users/> },
    { text: "כל השיחות", icon: <MailIcon />, component: <ProviderReviewForm /> },
    { text: MENU_TITLES.CLOSED_CONTACTS, icon: <InboxIcon />, component: <ProviderReviewForm/> },
  ];

  const menuItems2 = [
    { text: "הגדרות", icon: <MailIcon />, component: <ProviderReviewForm /> },
    {
      text: "התנתק",
      icon: <InboxIcon />,
      component: <ProviderReviewForm/>,
      action: "signout",
    },
  ];

  const handleMenuItemClick = (item) => {
    if (item.action === "signout") {
      signout();
    }
    setItem(item);
  };

  const signout = () => {
    localStorage.removeItem("authToken");
    dispatch(setUser(null));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            BidMe ADMIN
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />
        {React.cloneElement(item.component, { key: item.text })}
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            backgroundColor: theme.palette.background.paper, // Custom color change
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((menuItem, index) => (
            <ListItem key={menuItem.text} disablePadding>
              <ListItemButton onClick={() => handleMenuItemClick(menuItem)}>
                <ListItemText primary={menuItem.text} />
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {menuItems2.map((menuItem, index) => (
            <ListItem key={menuItem.text} disablePadding>
              <ListItemButton onClick={() => handleMenuItemClick(menuItem)}>
                <ListItemText primary={menuItem.text} />
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
// api.js

import { API_HOST } from "../config/config";

export function fetchProviderEmails() {
    const authToken = localStorage.getItem('authToken');
    if(authToken === null) throw new Error('No auth token found');

    return fetch(`${API_HOST}/providers_emails`, {
        headers: {
            'Authorization': `Bearer ${authToken}`,
        },
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
        
    });
}
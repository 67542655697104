import React, { useEffect, useState } from "react";
import "./Header.css"; // Import the CSS file
import { Link } from "react-router-dom";
import Modal from "./login/Modal";
import LoginSignup from "./login/LoginSignup";
import {  setUser } from "./redux_slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

const Header = ({ isLoggedIn, userName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [formType, setFormType] = useState("login");
  const user = useSelector((state) => state.user);
  

  


  const handleClose = () => {
    setIsModalOpen(false);
  };

  const signout = () => {
    localStorage.removeItem("authToken");
    dispatch(setUser(null));
  };

  const openModal = (souroce) => {
    setFormType(souroce);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, []);

 

  return (
    // <div className="font-poppins">This text will use the Poppins font.</div>
    <header className="bg-gradient-to-b from-blue-300 to-white shadow-md py-4 px-8">
      <div className="container mx-auto flex justify-between items-center relative">
        <div className="absolute left-1/2 transform -translate-x-1/2">
        

          <Link
          
            to="/"
            className=" text-3xl font-semibold flex" style={{ fontFamily: 'Pacifico, sans-serif' }} 
          >
            <span className="text-red-500">B</span>
            <span className="text-yellow-500">i</span>
            <span className="text-green-500">d</span>
            <span className="text-blue-500">m</span>
            <span className="text-indigo-500">e</span>
            <span>&nbsp;</span>

            <span className="text-red-500">a</span>
            <span className="text-yellow-500">d</span>
            <span className="text-green-500">m</span>
            <span className="text-blue-500">i</span>
            <span className="text-indigo-500">n</span>
          </Link>
        </div>

        {isLoggedIn ? (
          <div className="flex justify-between w-full">
            <div className="flex items-center space-x-4">
              
              <p className="text-gray-800 hidden sm:block">שלום, {userName}</p>
            </div>
            <button
              className="text-blue-500 hover:text-blue-400"
              onClick={signout}
            >
              התנתק
            </button>
          </div>
        ) : (
          <>
            <div className="hidden sm:flex items-center space-x-4">
              <button
                onClick={() => openModal("login")}
                className="text-blue-500 hover:text-blue-400"
              >
                התחבר
              </button>
              <span>/</span>
              <button
                onClick={() => openModal("signup")}
                className="text-blue-500 hover:text-blue-400"
              >
                הירשם
              </button>
            </div>

            {/* Mobile layout */}
            <div className="flex items-center justify-between w-full sm:hidden">
              <button
                onClick={() => openModal("login")}
                className="text-blue-500 hover:text-blue-400"
              >
                התחבר
              </button>
              <button
                onClick={() => openModal("signup")}
                className="text-blue-500 hover:text-blue-400"
              >
                הירשם
              </button>
            </div>
          </>
        )}
        {!isLoggedIn && isModalOpen && (
          <Modal>
            <div className="flex items-center justify-center h-full">
              <LoginSignup handleClose={handleClose} winType={formType} />
            </div>
          </Modal>
        )}
      </div>
    </header>
  );
};

export default Header;

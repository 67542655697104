import React, { useEffect, useState } from "react";
import fetchAdminUsers, { fetchUserDetails } from "../fetch/UsersDrawerFetch";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "../fetch/signoutAction";
import UnauthorizedError from "../errors/UnauthorizedError";
import { DataGrid } from '@mui/x-data-grid';

const Users = () => {
  const [searchField, setSearchField] = useState("name");
  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      dispatch(signout());
      return;
    }

    const fetchData = async () => {
      try {
        const data = await fetchAdminUsers(user.userId);
        setTableData(data.data);
      } catch (error) {
        if (error instanceof UnauthorizedError) {
          console.error(error.message);
          dispatch(signout());
        } else {
          console.error(error);
        }
      }
    };

    if (user) {
      fetchData();
    }
  }, [user, dispatch]);

  const filteredData = tableData.filter((item) => {
    const fieldValue = item[searchField] || item.area?.[searchField] || "";
    return fieldValue.toString().toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleRowSelection = (selectionModel) => {
    const selectedId = selectionModel[0]; // Get the first selected ID (assuming single selection)
    const selectedRowData = tableData.find((row) => row.requestId === selectedId);
    setSelectedRow(selectedRowData);
    console.log("Selected Row:", selectedRowData);
    if (!selectedRowData) return;
    // Fetch more data based on the selected row
  
    fetchUserDetails(selectedRowData.userId)
      .then((data) => {
        // Handle the fetched data
        setSelectedUser(data.data);
        console.log("Fetched User Details:", data);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof UnauthorizedError) {
          // Redirect to the login page
          window.location.href = "/login";
        }
      });
  };
    // focusOnUser(selectedRowData.userId);

    //     const fetchMoreData = async () => {
    //       try {
    //         // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint to fetch more data based on the selected row
    //         const response = await fetch('YOUR_API_ENDPOINT');
    //         const data = await response.json();
    //         // Process the fetched data
    //         console.log("Fetched More Data:", data);
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     };

    //     if (selectedRow) {
    //       fetchMoreData();
    //     }
  

    const columns = [
    { field: 'requestId', headerName: 'Request ID', width: 150, headerAlign: 'center', align: 'center' },
    { field: 'userId', headerName: 'User ID', width: 150, headerAlign: 'center', align: 'center' },
    { field: 'name', headerName: 'Name', width: 150, headerAlign: 'center', align: 'center' },
    { field: 'content', headerName: 'Content', width: 200, headerAlign: 'center', align: 'center' },
    { field: 'area', headerName: 'City', width: 150, headerAlign: 'center', align: 'center', valueGetter: (params) => {
      return params?.cityName || "No City";
    }},
    { field: 'created', headerName: 'Created', width: 150, headerAlign: 'center', align: 'center' },
    { field: 'lastUpdated', headerName: 'Last Updated', width: 150, headerAlign: 'center', align: 'center' },
    { field: 'category', headerName: 'Category', width: 150, headerAlign: 'center', align: 'center' },
    { field: 'subCategory', headerName: 'Sub Category', width: 150, headerAlign: 'center', align: 'center' },
    { field: 'phone', headerName: 'Phone', width: 150, headerAlign: 'center', align: 'center' },
  ];

  if (!tableData || tableData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4" dir="rtl">
      <div className="mb-4 flex items-center">
        <select
          value={searchField}
          onChange={(e) => setSearchField(e.target.value)}
          className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2"
        >
          <option value="name">שם</option>
          <option value="email">אימייל</option>
          <option value="phone">טלפון</option>
          <option value="cityName">עיר</option>
          <option value="districtName">מחוז</option>
        </select>
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={filteredData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.requestId}
          rowSelection // Enable row selection with checkboxes
          onRowSelectionModelChange={(newSelectionModel) => handleRowSelection(newSelectionModel)}
        />
      </div>
      {selectedUser && (
        <div className="mt-4 p-4 border border-gray-300 rounded-md bg-white shadow-md">
          <h2 className="text-2xl font-bold mb-2">{selectedRow.name}</h2>
          <p><strong>אימייל:</strong> {selectedUser.email}</p>
          <p><strong>טלפון:</strong> {selectedUser.phoneNumber}</p>
        </div>
      )}
    </div>
  );
};

export default Users;

// UserPage.jsx

import React, { useState, useEffect } from 'react';
import { fetchProviderEmails } from '../fetch/providerFetcher';

function UserPage({ goBack }) {
    const [emails, setEmails] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState('');

    useEffect(() => {
        if(localStorage.getItem('providerEmails') !== null){
            setEmails(JSON.parse(localStorage.getItem('providerEmails')));
            return;
        }
        fetchProviderEmails().then(data => {  
            localStorage.setItem('providerEmails', JSON.stringify(data.data));          
            setEmails(data.data)});
    }, []);

    const filteredEmails = selectedEmail
        ? emails.filter(email => email.includes(selectedEmail))
        : emails;



    return (
        <div className="flex flex-col items-start justify-start min-h-screen bg-gray-100 p-4">
            <div className="w-full flex justify-between">
                <button 
                    onClick={goBack}
                    className="px-4 py-2 font-semibold text-blue-700 bg-transparent rounded hover:bg-blue-500 hover:text-white hover:border-transparent"
                >
                    Go Back
                </button>
                <h1 className="text-2xl font-bold text-gray-900">Provider</h1>
                <div></div> {/* This empty div is needed to keep space between button and title */}
            </div>
            <div className="flex items-center">
                <input 
                    list="emails"
                    value={selectedEmail}
                    onChange={e => setSelectedEmail(e.target.value)}
                    className="mt-4 mr-2"
                />
                <button 
                    onClick={() => console.log('Load button clicked')} // replace with your actual event handler
                    className="px-4 py-2 font-semibold text-blue-700 bg-transparent rounded hover:bg-blue-500 hover:text-white hover:border-transparent"
                >
                    Load
                </button>
            </div>
            <datalist id="emails">
                {filteredEmails.map((email, index) => (
                    <option key={index} value={email} />
                ))}
            </datalist>
        </div>
    );

    // ... rest of your component
}

export default UserPage;
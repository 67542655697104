// ProfileSelection.js

import React, { useEffect, useState } from 'react';
import './ProfileSelection.css'; // Import the CSS file
import UserPage from '../user/UserPage';
import ProviderPage from '../provider/ProviderPage';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../redux_slices/userActions';
import { setUser } from '../redux_slices/userSlice';
import Modal from '../login/Modal';
import LoginSignup from '../login/LoginSignup';
import PersistentDrawerRight from '../drawer/PersistentDrawerRight';

function ProfileSelection() {
    const [profileType, setProfileType] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const user = useSelector(state => state.user);

    const selectProfile = (type) => {
        setShowModal(true);
        setProfileType(type);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if(user === null && localStorage.getItem("authToken")){
            fetchUser(localStorage.getItem("authToken")).then((data) => {
                if (data && data.data && data.data.user) {
                    dispatch(setUser(data.data.user));
                }
            }
            );};
    }, [user, dispatch]); // added dispatch to the dependency array



    if(profileType !== null && user !== null){
        if (profileType === 'user') {
            return <PersistentDrawerRight />;
        } else if (profileType === 'provider') {
            return <ProviderPage  goBack={()=>setProfileType(null)}/>;
        }
    }

    function handleClose(){
        setShowModal(false);
    }

    return (
        <>
        <div className="profile-selection">
            <div className="profile-square" onClick={() => selectProfile('user')}>
                Users
            </div>
            <div className="profile-square" onClick={() => selectProfile('provider')}>
                Provider
            </div>
            {showModal && !user && (
                <Modal>
                    <div className="flex items-center justify-center h-3/4">
                    <LoginSignup
                        style={{ maxWidth: "32rem", width: "100%" }}
                        handleClose={handleClose}
                    />
                    </div>
              </Modal>
            )}
        </div>
        </>
    );
}

export default ProfileSelection;